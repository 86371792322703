import React, { useEffect, useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  DateField,
  TopToolbar,
  NumberInput,
  SelectInput,
  useNotify,
  AutocompleteInput,
} from "react-admin";
import { Button, Chip, Badge } from "@material-ui/core";
import classNames from "classnames";
import { ImpersonateOrg, SendOrgRestPWButton } from "../../components";
import { getSeasonId } from "../../customState";
import OpenInNew from "@material-ui/icons/OpenInNew";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useSelector } from "react-redux";
import {
  formatDateOnlyString,
  orgProfitStatusOptions,
  repCommissionStatusOptions,
  orgProfitPostCloseoutStatusOptions,
  APIClient,
  generateAuthHeader,
  isCompanyDataEntry,
  isCompanyOrCompanyAdminOrRep,
  isCompanyAdminOrDataEntry,
} from "../../lib";
import { CampaignsExport } from "./components/CampaignsExport";
import { styles } from "./Campaign.styles";

const CampaignFilter = ({
  campaignManagementEnabled,
  productGroups,
  reps,
  prizePrograms,
  role,
  setFilters,
  ...props
}) => {
  const classes = styles();
  return (
    <div>
      <Button
        color="primary"
        size="small"
        onClick={() => setFilters({})}
        startIcon={<HighlightOffIcon />}
      >
        clear all filters
      </Button>
      <Filter {...props} setFilters={setFilters}>
        <NumberInput label="Campaign ID" source="campaign_id" alwaysOn />
        <TextInput label="Org Name/ID" source="q" alwaysOn />
        {campaignManagementEnabled && (
          <SelectInput
            source="org_profit_status"
            label="Org Profit Status"
            choices={orgProfitStatusOptions}
            translateChoice={false}
            alwaysOn
          />
        )}
        {campaignManagementEnabled && (
          <SelectInput
            source="org_profit_post_closeout_status"
            label="Org Profit PCO Status"
            choices={orgProfitPostCloseoutStatusOptions}
            className={classes.wideSelect}
            translateChoice={false}
            alwaysOn
          />
        )}
        {campaignManagementEnabled && (
          <SelectInput
            source="rep_commission_status"
            label="Rep Commission Status"
            choices={repCommissionStatusOptions}
            translateChoice={false}
            alwaysOn
            className={classes.wideSelect}
          />
        )}
        <SelectInput
          source="supplier_product_group_id"
          label="Product Group"
          choices={productGroups}
          translateChoice={false}
          alwaysOn
        />
        {isCompanyAdminOrDataEntry(role) && (
          <AutocompleteInput
            source="rep_id"
            label="Rep"
            choices={reps}
            translateChoice={false}
            alwaysOn
          />
        )}
        <SelectInput
          source="company_prize_program_id"
          label="Prize Program"
          choices={prizePrograms}
          translateChoice={false}
          alwaysOn
        />
      </Filter>
    </div>
  );
};

function CampaignActions({ season_id, filterValues }) {
  return (
    <TopToolbar>
      <CampaignsExport season_id={season_id} filterValues={filterValues} />
    </TopToolbar>
  );
}

export default function CampaignList(props) {
  const { location, resource, basePath } = props;
  const classes = styles();
  const notify = useNotify();
  const [reps, setReps] = useState([]);
  const [prizePrograms, setPrizePrograms] = useState([]);
  const season_id = useSelector(getSeasonId);
  const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);
  const role = useSelector(state => state.user.role);
  const campaignManagementEnabled = useSelector(
    state => state.user.campaignManagementEnabled,
  );
  const [productGroups, setProductGroups] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        "/supplier_product_groups/company_group_autocomplete",
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setProductGroups(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/reps/autocomplete", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setReps(data);
    };
    if (isCompanyAdminOrDataEntry(role)) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/company_prize_programs/autocomplete", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setPrizePrograms(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controllerProps = {
    location,
    resource,
    basePath,
    perPage: 25,
    sort: { field: "id", order: "DESC" },
    filter: { season_id },
  };

  const GridWrapper = ({ ...props }) => {
    return (
      <div
        className={
          sidebarOpen ? classes.datagridBarOpen : classes.datagridBarClosed
        }
      >
        <Datagrid {...props} rowClick="show">
          <TextField source="id" label="ID" />
          <TextField source="name" label="Campaign Name" />
          <FunctionField
            label="Status"
            render={({ status }) => (
              <Chip
                label={status}
                size="small"
                className={classNames(
                  classes.statusChip,
                  status === "active"
                    ? classes.activeChip
                    : status === "inactive"
                    ? classes.inactiveChip
                    : classes.endedChip,
                )}
              />
            )}
          />
          {campaignManagementEnabled && (
            <FunctionField
              label="Org Profit Status"
              render={record => {
                const {
                  orgProfitStatus: OPS,
                  profit_paid_via_ach,
                  ach_confirmed,
                } = record;
                const status = OPS ? OPS : "awaiting end";
                return (
                  <Badge
                    badgeContent={profit_paid_via_ach ? "ach" : null}
                    overlap="rectangular"
                    classes={{
                      badge: ach_confirmed ? classes.badge : classes.alertBadge,
                    }}
                  >
                    <Chip label={status} size="small" />
                  </Badge>
                );
              }}
            />
          )}
          {campaignManagementEnabled && (
            <FunctionField
              label="Rep Commission Status"
              render={({ repCommissionStatus }) => {
                if (!repCommissionStatus) return <></>;
                return <Chip label={repCommissionStatus} size="small" />;
              }}
            />
          )}
          {/* <TextField source="org_id" label="Org ID" /> */}
          <TextField source="orgName" sortable={false} label="Org Name" />
          <TextField source="repName" sortable={false} label="Rep" />
          <DateField source="start_date" />
          <DateField source="end_date" />
          {!isCompanyDataEntry(role) && (
            <FunctionField
              label="Imp."
              render={({ id, org_id }) => (
                <div className={classes.impersonateLink}>
                  <ImpersonateOrg orgId={org_id} campaignId={id} />
                </div>
              )}
            />
          )}
          <FunctionField
            label="Link"
            render={({ webLink }) => (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                className={classes.siteLink}
                href={webLink}
                target="_blank"
                // rel="noopener"
                onClick={e => e.stopPropagation()}
              >
                <OpenInNew />
              </a>
            )}
          />
          <TextField source="group_name" label="PG" />
          <TextField
            source="prizeProgram"
            label="Prize Program"
            sortable={false}
          />
          <TextField source="student_count" label="Student Count" />
          <TextField
            source="registeredStudents"
            sortable={false}
            label="Registered Students"
          />
          <TextField
            source="contactCount"
            sortable={false}
            label="Contact Count"
          />
          <TextField source="goal" />
          <TextField source="raised" />
          <FunctionField
            source="launch_packet_needed_by"
            label="LP Needed"
            render={({ launch_packet_needed_by }) => (
              <div>{formatDateOnlyString(launch_packet_needed_by)}</div>
            )}
          />
          <FunctionField
            source="kickoff_date"
            label="Kickoff"
            render={({ kickoff_date }) => (
              <div>{formatDateOnlyString(kickoff_date)}</div>
            )}
          />
          <FunctionField
            source="order_forms_to_org_due_date"
            label="Forms Due Org"
            render={({ order_forms_to_org_due_date }) => (
              <div>{formatDateOnlyString(order_forms_to_org_due_date)}</div>
            )}
          />
          <FunctionField
            source="order_form_due_date"
            label="Forms Due Co."
            render={({ order_form_due_date }) => (
              <div>{formatDateOnlyString(order_form_due_date)}</div>
            )}
          />
          {campaignManagementEnabled && (
            <FunctionField
              label="PCO Status"
              render={({ orgProfitPostCloseoutStatus }) => {
                if (!orgProfitPostCloseoutStatus) return <></>;
                return (
                  <Chip label={orgProfitPostCloseoutStatus} size="small" />
                );
              }}
            />
          )}
          {isCompanyOrCompanyAdminOrRep(role) && (
            <FunctionField
              label="Send Org PW Rest link"
              render={record => <SendOrgRestPWButton record={record} />}
            />
          )}
        </Datagrid>
      </div>
    );
  };

  return (
    <List
      syncWithLocation
      title="Campaigns"
      {...controllerProps}
      perPage={25}
      bulkActionButtons={false}
      filters={
        <CampaignFilter
          campaignManagementEnabled={campaignManagementEnabled}
          productGroups={productGroups}
          reps={reps}
          prizePrograms={prizePrograms}
          role={role}
        />
      }
      actions={<CampaignActions season_id={season_id} />}
      empty={false}
    >
      <GridWrapper />
    </List>
  );
}
