import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  makeStyles,
  TableHead,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useNotify } from "react-admin";
import { APIClient, generateAuthHeader, isSchoolathonCompany } from "../../lib";
import { PrizeProgramTier } from "./PrizeProgramTier";
import { NoPrizeProgram } from "./NoPrizeProgram";
import { AssignPrizesModal } from "./AssignPrizesModal";

export function PrizeProgram({ campaign_id, refetchListRef }) {
  const classes = styles();
  const notify = useNotify();
  const [programName, seProgramName] = useState("");
  const [programId, seProgramId] = useState(null);
  const [noProgram, setNoProgram] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [prizePicksAllowed, sePrizePicksAllowed] = useState(false);
  const [hasTierNotifications, setHasTierNotifications] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [runningDyePackAction, setRunningDyePackAction] = useState(false);
  const { canManage, companyId } = useSelector(state => state.user);
  const isSchoolathon = isSchoolathonCompany(companyId);

  useEffect(() => {
    if (campaign_id) fetchPrizeProgramData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchPrizeProgramData = async () => {
    const res = await APIClient.get(
      `/campaign_prize_programs/campaign/${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data, status } = res;
    if (status === 404) return setNoProgram(true);
    if (error) return notify(errorMessage, "warning");
    const {
      id,
      program_name,
      campaign_prize_program_tiers,
      allow_student_picks,
      send_approaching_tier_notifications,
    } = data;
    seProgramId(id);
    seProgramName(program_name);
    setTiers(campaign_prize_program_tiers);
    sePrizePicksAllowed(allow_student_picks);
    setHasTierNotifications(send_approaching_tier_notifications);
  };

  const refresh = () => {
    setNoProgram(false);
    fetchPrizeProgramData();
  };

  const schoolathonCoAddDyePacksToOrders = async () => {
    setRunningDyePackAction(true);
    const res = await APIClient.post(
      `/donation_products/schoolathon_custom_add_dye_to_orders?campaign_id=${campaign_id}`,
      {},
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, { type: "warning" });
    notify("Dye Products added successfully", { type: "info" });
  };

  if (noProgram) {
    return <NoPrizeProgram campaign_id={campaign_id} refresh={refresh} />;
  }
  if (!programId) return <CircularProgress color="primary" />;
  return (
    <div className={classes.container}>
      <div className={classes.topContent}>
        <div className={classes.programName}>{programName}</div>
        <div>
          {canManage && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setShowAssignModal(true)}
            >
              Assign Prizes
            </Button>
          )}
          {isSchoolathon && canManage && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={schoolathonCoAddDyePacksToOrders}
              className={classes.dyePackBtn}
              disabled={runningDyePackAction}
            >
              Add Dye Packs To Missing Orders
            </Button>
          )}
        </div>
      </div>

      <TableContainer component={Paper} elevation={1}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Tier Name</TableCell>
              <TableCell>Order Number</TableCell>
              <TableCell>{`Tier Rule Data Point(s)`}</TableCell>
              <TableCell>{`Tier Rule Minimum(s)`}</TableCell>
              {hasTierNotifications && (
                <TableCell>{`Approaching-Tier Notification Minimum(s)`}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tiers.map((tier, index) => {
              return (
                <PrizeProgramTier
                  tier={tier}
                  hasTierNotifications={hasTierNotifications}
                  key={index}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AssignPrizesModal
        campaign_id={campaign_id}
        showAssignModal={showAssignModal}
        setShowAssignModal={setShowAssignModal}
        refetchListRef={refetchListRef}
        prizePicksAllowed={prizePicksAllowed}
      />
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: { margin: "12px 0 24px 0" },
  topContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  programName: {
    fontSize: 18,
    fontWeight: 600,
  },
  dyePackBtn: {
    marginLeft: 18,
  },
}));
