import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, requiredField, requiredInteger } from "../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../types";
import { setToast } from "../../state";
import {
  ButtonSpinner,
  ConfirmationDialog,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
} from "../../components";
import { useDrawerTransition, useIsMobile } from "../../hooks/ui";
import { RootState } from "../../types/state";
import {
  communicationTypeConfigs,
  CommunicationTypes,
} from "../../types/communications";
import { EmailWYSIWYGField } from "../../components/communication/EmailWYSIWYGField";
import { RedButton } from "../ui/RedButton";

type Props = {
  onClose: () => void;
  refreshList: () => void;
  record: GenericObject;
  isTemplate?: boolean;
};

export function EditCommunication({
  onClose: _onClose,
  refreshList,
  record,
  isTemplate,
}: Props) {
  const { id, communication_type, email_subject, email_body } = record;
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [showDelete, setShowDelete] = useState(false);
  const communicationCategoryAutocomplete = useSelector(
    ({ communication }: RootState) =>
      communication.communicationCategoryAutocomplete,
  );
  const {
    offsetLabel,
    allowOrgLogoInsert,
    allowSocialMediaInsert,
    allowWebpageButton,
    allowVolunteerPictureInsert,
    noOffset,
    showPercents,
    allowCcRep,
    mergeFields,
    offsetOptions,
  } = communicationTypeConfigs[communication_type as CommunicationTypes];

  useEffect(() => {
    setInitialValues(record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const {
      communication_category_id,
      communication_type,
      name,
      active,
      email_subject,
      email_body,
      offset,
      progress_percentage_min,
      progress_percentage_max,
      insert_org_logo,
      insert_volunteer_picture,
      insert_social_media_links,
      cc_rep,
    } = values;
    const update = {
      communication_category_id,
      communication_type,
      name,
      active,
      email_subject,
      email_body,
      offset,
      progress_percentage_min: progress_percentage_min
        ? Number(progress_percentage_min)
        : null,
      progress_percentage_max: progress_percentage_max
        ? Number(progress_percentage_max)
        : null,
      insert_org_logo,
      insert_volunteer_picture,
      insert_social_media_links,
      cc_rep,
    };
    const url = `/communications/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
    refreshList();
  };

  const onDelete = async () => {
    const url = `/communications/${id}`;
    const res = await APIAuthClient.delete<any, APIRes>(url);
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Communication deleted", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, form, pristine }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Edit communication</h1>
                {isMobile && (
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              <div className={classes.mainContent}>
                {isTemplate && (
                  <div className={classes.activeWrapper}>
                    <SwitchField fieldName="active" label="Active" />
                  </div>
                )}
                {!isTemplate && <div className={classes.topSpace} />}
                <Field
                  component={TextFieldWrapper}
                  name="communication_type"
                  label="Communication type"
                  disabled
                  className={classes.input}
                />
                {isTemplate && (
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="communication_category_id"
                    label="Category"
                    validate={requiredField}
                    className={classes.input}
                  >
                    {communicationCategoryAutocomplete.map(c => {
                      const { id, name } = c;
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Field>
                )}
                <Field
                  component={TextFieldWrapper}
                  name="name"
                  label="Name"
                  validate={requiredField}
                  className={classes.input}
                />

                {!noOffset && (
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="offset"
                    label={offsetLabel}
                    validate={requiredInteger}
                    className={classes.input}
                  >
                    {offsetOptions.map(o => {
                      const { name, value } = o;
                      return (
                        <MenuItem key={name} value={value}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Field>
                )}

                {showPercents && (
                  <div className={classes.inputGroup}>
                    <Field
                      component={TextFieldWrapper}
                      name="progress_percentage_min"
                      label="Min percent"
                      validate={requiredInteger}
                      className={classes.halfInput}
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      component={TextFieldWrapper}
                      name="progress_percentage_max"
                      label="Max percent"
                      validate={requiredInteger}
                      className={classes.halfInput}
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}

                {allowOrgLogoInsert && (
                  <div className={classes.input}>
                    <SwitchField
                      fieldName="insert_org_logo"
                      label="Insert org logo"
                    />
                  </div>
                )}

                {allowVolunteerPictureInsert && (
                  <div className={classes.input}>
                    <SwitchField
                      fieldName="insert_volunteer_picture"
                      label="Insert student picture"
                    />
                  </div>
                )}

                {allowSocialMediaInsert && (
                  <div className={classes.input}>
                    <SwitchField
                      fieldName="insert_social_media_links"
                      label="Insert social media links"
                    />
                  </div>
                )}

                {allowWebpageButton && (
                  <div className={classes.input}>
                    <SwitchField
                      fieldName="insert_webpage_button"
                      label="Insert webpage button"
                    />
                  </div>
                )}

                {allowCcRep && (
                  <div className={classes.input}>
                    <SwitchField fieldName="cc_rep" label="cc rep" />
                  </div>
                )}

                <EmailWYSIWYGField
                  header="Email subject"
                  fieldName="email_subject"
                  mergeFields={mergeFields}
                  currentValue={email_subject as string}
                />
                <div className={classes.space} />
                <EmailWYSIWYGField
                  header="Email body"
                  fieldName="email_body"
                  mergeFields={mergeFields}
                  addMaterialMerge={Boolean(isTemplate)}
                  currentValue={email_body as string}
                />
              </div>

              <div className={classes.bottom}>
                <div>
                  {!isTemplate && (
                    <RedButton
                      variant="text"
                      disabled={submitting}
                      onClick={() => setShowDelete(true)}
                      startIcon={<DeleteIcon />}
                    >
                      DELETE
                    </RedButton>
                  )}
                </div>
                <div className={classes.saveAndCancel}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={pristine || submitting}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
      {showDelete && (
        <ConfirmationDialog
          title="Are you sure you want to delete this communication?"
          actionFunc={onDelete}
          onClose={() => setShowDelete(false)}
          redButton
          actionLabel="DELETE"
        />
      )}
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      position: "sticky",
      top: 0,
      minHeight: "unset",
      borderBottom: "none",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: "18px 24px 16px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 0,
    },
  },
  topSpace: {
    height: 12,
  },
  space: {
    height: 32,
  },
  activeWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 18,
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
      paddingBottom: 8,
    },
  },
  saveAndCancel: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
