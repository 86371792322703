import { APIAuthClient } from "../../lib";
import { APIRes } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";
import { CommunicationAutocomplete } from "../../types/communications";

const communicationTypes = {
  COMMUNICATION_AUTOCOMPLETE_SET: "COMMUNICATION_AUTOCOMPLETE_SET",
  COMMUNICATION_CATEGORIES_AUTOCOMPLETE_SET:
    "COMMUNICATION_CATEGORIES_AUTOCOMPLETE_SET",
};

const communicationActions = {
  fetchCommunicationAutocomplete(): AppThunkAction {
    return async dispatch => {
      const url = "/communications/autocomplete";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      const communicationAutocomplete = data;
      const communicationAutocompleteIdMap = {};
      (data as CommunicationAutocomplete).forEach(
        e => (communicationAutocompleteIdMap[e.id] = e),
      );

      dispatch({
        type: communicationTypes.COMMUNICATION_AUTOCOMPLETE_SET,
        data: { communicationAutocomplete, communicationAutocompleteIdMap },
      });
    };
  },
  fetchCommunicationCategoryAutocomplete(): AppThunkAction {
    return async dispatch => {
      const url = "/communication_categories/autocomplete";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));

      dispatch({
        type: communicationTypes.COMMUNICATION_CATEGORIES_AUTOCOMPLETE_SET,
        data,
      });
    };
  },
};

export { communicationActions, communicationTypes };
