import { GenericAutocomplete } from "../../types";
import { CommunicationAutocomplete } from "../../types/communications";
import { communicationTypes } from "./actions";

type CommunicationState = {
  communicationCategoryAutocomplete: GenericAutocomplete;
  communicationAutocomplete: CommunicationAutocomplete;
  communicationAutocompleteIdMap: { [k: string]: { id: number; name: string } };
};

const initialState: CommunicationState = {
  communicationCategoryAutocomplete: [],
  communicationAutocomplete: [],
  communicationAutocompleteIdMap: {},
};

export const communicationReducers = (
  state = initialState,
  action: any,
): CommunicationState => {
  switch (action.type) {
    case communicationTypes.COMMUNICATION_AUTOCOMPLETE_SET:
      return {
        ...state,
        ...action.data,
      };
    case communicationTypes.COMMUNICATION_CATEGORIES_AUTOCOMPLETE_SET:
      return {
        ...state,
        communicationCategoryAutocomplete: action.data,
      };
    default:
      return state;
  }
};
