import { useEffect, useState } from "react";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsMobile, useScrollToTop } from "../../../hooks/ui";
import {
  FilterChips,
  FilterDrawerButton,
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
} from "../../../components";
import { useList } from "../../../hooks/list";
import { AddCommunication } from "./AddCommunication";
import { CommunicationRows } from "./CommunicationRows";
import { CommunicationFilters } from "./CommunicationFilters";
import { NoResultsSVG } from "../../../assets";
import {
  CommunicationFields,
  communicationSearchFields,
  communicationSortFields,
} from "../../../types/communications";
import { useDispatch } from "react-redux";
import { communicationActions } from "../../../state";

export function Communications() {
  const classes = styles();
  const dispatch = useDispatch();
  const [showAdd, setShowAdd] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const isMobile = useIsMobile();
  useScrollToTop();

  const listProps = useList<any>({
    baseUrl: "/communications",
    isReady: true,
    initialState: { sort: "name", order: "asc" },
    filterFields: CommunicationFields,
  });
  const {
    listIsFiltered,
    total,
    noResults,
    refreshList,
    showRows,
    filterDrawerActive,
  } = listProps;

  useEffect(() => {
    dispatch(communicationActions.fetchCommunicationCategoryAutocomplete());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListContainer listProps={listProps} topOffset={148}>
      {isMobile && (
        <div className={classes.mobileTop}>
          <div className={classes.count}>
            {total} Communication
            {total !== 1 ? "s" : ""}
          </div>
          <div>
            <IconButton onClick={() => setShowSort(true)}>
              <SwapVertIcon />
            </IconButton>
            <span className={classes.buttonSpacer} />
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
            <span className={classes.buttonSpacer} />
            <IconButton onClick={() => setShowAdd(true)}>
              <AddIcon />
            </IconButton>
          </div>
        </div>
      )}
      <div className={classes.searchRow}>
        <MultiSearch listProps={listProps} fields={communicationSearchFields} />
        {!isMobile && (
          <div>
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
            <span className={classes.buttonSpacer} />
            <Button
              color="primary"
              className={classes.add}
              startIcon={<AddIcon />}
              onClick={() => setShowAdd(true)}
            >
              Communication
            </Button>
          </div>
        )}
      </div>

      <FilterChips listProps={listProps} />

      {noResults && (
        <ListNoResults
          SVG={NoResultsSVG}
          listIsFiltered={listIsFiltered}
          label="communication"
          buttonLabel="Add communication"
          buttonFunc={() => setShowAdd(true)}
        />
      )}

      {showRows && <CommunicationRows listProps={listProps} />}

      {showAdd && (
        <AddCommunication
          onClose={() => setShowAdd(false)}
          refreshList={refreshList}
        />
      )}
      {showFilter && (
        <CommunicationFilters
          onClose={() => setShowFilter(false)}
          listProps={listProps}
        />
      )}
      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={communicationSortFields}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  mobileTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
  },
  add: {
    borderRadius: 20,
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  buttonSpacer: {
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
    },
  },
}));
