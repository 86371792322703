import React, { useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  ImageField,
  Filter,
  TextInput,
  downloadCSV,
  FunctionField,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { useSelector } from "react-redux";
import jsonExport from "jsonexport/dist";
import OpenInNew from "@material-ui/icons/OpenInNew";
import PublishIcon from "@material-ui/icons/Publish";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Chip, Button } from "@material-ui/core";
import { ImpersonateOrg } from "../../components";
import { APIUtilClient, generateAuthHeader, mainSiteUrl } from "../../lib";
import { getIsSuperAdminOrSuperAdminImpersonator } from "../../customState";
import keyBy from "lodash/keyBy";
import classNames from "classnames";
import { UploadOrgsModal } from "./UploadOrgsModal";
import { styles } from "./OrgViews.styles";

function OrgList(props) {
  const classes = styles();

  return (
    <div>
      <List
        {...props}
        perPage={25}
        title="Organizations"
        bulkActionButtons={false}
        filters={<OrgFilter />}
        actions={<OrgActions />}
        exporter={exporter}
      >
        <Datagrid rowClick="show">
          <FunctionField
            source="id"
            label="Org"
            sortable={true}
            render={({ id }) => (
              <div>
                <span className={classes.idLabel}>ORG</span> #{id}
              </div>
            )}
          />
          {/* <TextField source="id" label="Org" sortable={true} /> */}
          <ImageField
            source="logo"
            classes={{ image: classes.image }}
            sortable={false}
          />
          <TextField source="name" sortable={true} />
          <TextField source="slug" label="Unique URL" sortable={true} />
          <TextField source="rep_name" label="Rep" sortable={false} />
          <FunctionField
            label="Impersonate"
            render={record => (
              <div>
                <ImpersonateOrg orgId={record.id} />
              </div>
            )}
          />
          <Campaigns label="Campaigns" />
        </Datagrid>
      </List>
    </div>
  );
}

export default OrgList;

function OrgActions({ basePath }) {
  const isSuperAdmin = useSelector(state =>
    getIsSuperAdminOrSuperAdminImpersonator(state),
  );
  const [uploadModal, setUploadModal] = useState(false);

  const download = async () => {
    const res = await APIUtilClient.get("/orgs/export", {
      headers: generateAuthHeader(),
    });

    const { error, data, errorMessage } = res;
    if (error) {
      return alert(
        errorMessage ||
          "There was an error downloading your report, please try again later.",
      );
    }

    jsonExport(data, (err, csv) => {
      downloadCSV(csv, "Orgs");
    });
  };

  return (
    <TopToolbar>
      {isSuperAdmin && (
        <Button
          size="small"
          color="primary"
          onClick={() => setUploadModal(true)}
          startIcon={<PublishIcon />}
        >
          csv import
        </Button>
      )}
      <CreateButton label="add new org" basePath={basePath} />
      <Button
        size="small"
        color="primary"
        onClick={download}
        startIcon={<DownloadIcon />}
      >
        export
      </Button>
      {uploadModal && <UploadOrgsModal setUploadModal={setUploadModal} />}
    </TopToolbar>
  );
}

const OrgFilter = props => {
  const classes = styles();
  return (
    <Filter {...props}>
      <TextInput label="Org ID/Name" source="q" alwaysOn />
      <TextInput
        label="Search Campaign External Identifier"
        source="external_identifier"
        alwaysOn
        className={classes.externalIdentifierInput}
      />
    </Filter>
  );
};

const exporter = orgs => {
  const formattedOrg = orgs.map(o => {
    const { id, name, slug, campaigns } = o;
    const exportObj = {
      ID: id,
      Name: name,
      "Unique URL": slug,
      Campaigns: campaigns.map(c => c.name).join(",\n "),
    };
    return exportObj;
  });
  jsonExport(formattedOrg, (err, csv) => {
    downloadCSV(csv, "Orgs");
  });
};

const Campaigns = ({ record }) => {
  const classes = styles();
  const { id: orgId, campaigns = [], slug, company_frontend_url } = record;
  return (
    <div onClick={e => e.stopPropagation()}>
      {campaigns.length > 0 && (
        <Datagrid
          data={keyBy(campaigns, "id")}
          ids={campaigns.map(({ id }) => id)}
          currentSort={{ field: "id", order: "DESC" }}
          basePath="/campaigns"
          rowClick="show"
        >
          <TextField source="id" label="campaign ID" sortable={false} />
          <FunctionField
            label="Status"
            render={({ status }) => (
              <Chip
                label={status}
                size="small"
                className={classNames(
                  classes.statusChip,
                  status === "active"
                    ? classes.activeChip
                    : status === "inactive"
                    ? classes.inactiveChip
                    : classes.endedChip,
                )}
              />
            )}
          />
          <TextField source="name" label="campaign Name" sortable={false} />
          <TextField
            source="external_identifier"
            label="External Identifier"
            sortable={false}
          />
          <FunctionField label="Raised" render={({ raised }) => raised} />
          <FunctionField
            label="Impersonate"
            render={record => (
              <div className={classes.impersonateLink}>
                <ImpersonateOrg orgId={orgId} campaignId={record.id} />
              </div>
            )}
          />

          <FunctionField
            label="Webpage"
            render={record => {
              const { campaign_slug } = record;
              return (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a
                  className={classes.siteLink}
                  href={mainSiteUrl(
                    slug,
                    campaign_slug,
                    undefined,
                    company_frontend_url,
                  )}
                  target="_blank"
                  rel="noopener"
                  onClick={e => e.stopPropagation()}
                >
                  <OpenInNew />
                </a>
              );
            }}
          />
        </Datagrid>
      )}
    </div>
  );
};
