import React, { useEffect, useState } from "react";
import jsonExport from "jsonexport/dist";
import { useNotify, downloadCSV } from "react-admin";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { Button, Paper, makeStyles, MenuItem } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import {
  APIClient,
  APIUtilClient,
  generateAuthHeader,
  requiredInteger,
  integerOnly,
  required,
} from "../../lib";
import classNames from "classnames";

export const SuperAdminTools = () => {
  const classes = styles();
  const notify = useNotify();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/companies/autocomplete", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setCompanies(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productAddSubmit = async values => {
    const { campaign_id, product_id } = values;

    const res = await APIUtilClient.post(
      `/campaign_products`,
      { campaign_id: Number(campaign_id), product_id: Number(product_id) },
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Product added successfully", { type: "info" });
  };

  const redoProductsSubmit = async values => {
    const { campaign_id, new_product_group_id } = values;

    const res = await APIUtilClient.post(
      `/campaign_products/redo_campaign_products`,
      {
        campaign_id: Number(campaign_id),
        new_product_group_id: new_product_group_id
          ? Number(new_product_group_id)
          : null,
      },
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Campaign's Products redone successfully", { type: "info" });
  };

  const redoDigitalContentProductsSubmit = async values => {
    const { campaign_id, digital_content_inventory_group_id } = values;

    const res = await APIUtilClient.post(
      `/campaign_products/redo_digital_content_campaign_products`,
      {
        campaign_id: Number(campaign_id),
        digital_content_inventory_group_id: Number(
          digital_content_inventory_group_id,
        ),
      },
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Campaign's Digital Content Products redone successfully", {
      type: "info",
    });
  };

  const companyCampaignReport = async values => {
    const { company_id } = values;
    const res = await APIUtilClient.get(
      `/companies/${company_id}/campaign_report`,
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");

    jsonExport(data, (err, csv) => {
      downloadCSV(csv, "Company Campaign Report");
    });
  };

  const updateOrgRepSubmit = async values => {
    const { org_id, rep_id } = values;

    const res = await APIUtilClient.put(
      `/reps/update_org_rep`,
      { org_id: Number(org_id), rep_id: Number(rep_id) },
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Org Rep updated successfully", { type: "info" });
  };

  const deleteCampaignPrizeProgram = async ({ campaign_id }) => {
    const res = await APIUtilClient.delete(
      `/campaign_prize_programs/delete_campaign_prize_program?campaign_id=${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("Prize program deleted successfully", { type: "info" });
  };

  const updateCampaignsWithMissingGroupProducts = async ({
    supplier_product_group_id,
  }) => {
    const res = await APIUtilClient.put(
      `/supplier_product_group_products/update_campaigns_with_missing_product_group_products?supplier_product_group_id=${supplier_product_group_id}`,
      {},
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("Campaign updated successfully", { type: "info" });
  };

  const moveVolunteerCampaignDonationsToDifferentCampaign = async values => {
    const {
      old_campaign_id,
      new_campaign_id,
      old_volunteer_id,
      new_volunteer_id,
    } = values;
    const body = {
      old_campaign_id: Number(old_campaign_id),
      new_campaign_id: Number(new_campaign_id),
      old_volunteer_id: Number(old_volunteer_id),
      new_volunteer_id: Number(new_volunteer_id),
    };
    const res = await APIUtilClient.put(
      "/volunteer_campaigns/move_donations_to_different_campaign",
      body,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("Donations moved over successfully", { type: "info" });
  };

  const redoCommunicationBundle = async values => {
    const { campaign_id, communication_bundle_id } = values;

    const res = await APIUtilClient.post(
      `/communications/redo_campaign_communications`,
      {
        campaign_id: Number(campaign_id),
        communication_bundle_id: Number(communication_bundle_id),
      },
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Campaign's communications redone successfully", { type: "info" });
  };

  return (
    <Paper className={classes.container}>
      <div className={classes.title}>Add Campaign Product</div>
      <Form
        onSubmit={productAddSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="campaign_id"
                label="Campaign ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />

              <Field
                name="product_id"
                label="Product ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  startIcon={<SaveIcon />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />

      <div className={classes.title}>Redo Campaign's Products</div>
      <Form
        onSubmit={redoProductsSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="campaign_id"
                label="Campaign ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />

              <Field
                name="new_product_group_id"
                label="Product Group ID (opt.)"
                variant="outlined"
                component={TextField}
                validate={integerOnly}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  startIcon={<SaveIcon />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />

      <div className={classes.title}>
        Redo/Add Campaign's Digital Content Products
      </div>
      <Form
        onSubmit={redoDigitalContentProductsSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="campaign_id"
                label="Campaign ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />

              <Field
                name="digital_content_inventory_group_id"
                label="DC Inventory Group ID (NOT DC ID!)"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  startIcon={<SaveIcon />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />

      <div className={classes.title}>Company Campaign Report</div>
      <Form
        onSubmit={companyCampaignReport}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                select
                name="company_id"
                label="Select a Company"
                variant="outlined"
                component={TextField}
                validate={required}
                InputLabelProps={{ shrink: true }}
                className={classNames(classes.input, classes.select)}
              >
                {companies.map(({ id, company_name }) => (
                  <MenuItem key={id} value={id}>
                    {company_name}
                  </MenuItem>
                ))}
              </Field>
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  fetch
                </Button>
              </div>
            </form>
          );
        }}
      />

      <div className={classes.title}>Update Org Rep</div>
      <Form
        onSubmit={updateOrgRepSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="org_id"
                label="Org ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <Field
                name="rep_id"
                label="Rep ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  startIcon={<SaveIcon />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />

      <div className={classes.title}>
        {`DELETE a campaign prize program (including student prize picks)`}
      </div>
      <Form
        onSubmit={deleteCampaignPrizeProgram}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="campaign_id"
                label="Campaign ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  startIcon={<SaveIcon />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />

      <div className={classes.title}>
        Update current campaigns with missing product group products
      </div>
      <Form
        onSubmit={updateCampaignsWithMissingGroupProducts}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="supplier_product_group_id"
                label="Supplier Product Group ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  startIcon={<SaveIcon />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />

      <div className={classes.title}>
        Move volunteer campaign donations to a different campaign
      </div>
      <Form
        onSubmit={moveVolunteerCampaignDonationsToDifferentCampaign}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="old_campaign_id"
                label="Old Campaign ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <Field
                name="old_volunteer_id"
                label="Old Volunteer ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <Field
                name="new_campaign_id"
                label="New Campaign ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <Field
                name="new_volunteer_id"
                label="New Volunteer ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  startIcon={<SaveIcon />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />

      <div className={classes.title}>Redo Campaign's Communication Bundle</div>
      <Form
        onSubmit={redoCommunicationBundle}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="campaign_id"
                label="Campaign ID"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />

              <Field
                name="communication_bundle_id"
                label="Communication Bundle Id"
                variant="outlined"
                component={TextField}
                validate={requiredInteger}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  startIcon={<SaveIcon />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />
    </Paper>
  );
};

const styles = makeStyles(_theme => ({
  container: {
    marginTop: 16,
    padding: "48px 16px 24px 16px",
    width: "100%",
    minHeight: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    paddingBottom: 36,
  },
  input: {
    margin: "0 8px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
    marginBottom: 36,
  },
  select: {
    minWidth: 300,
  },
}));
