import { useEffect, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsMobile, useScrollToTop } from "../../../hooks/ui";
import { ListContainer, ListNoResults, MobileListSort } from "../..";
import { useList } from "../../../hooks/list";
import { CampaignCommunicationRows } from "./CampaignCommunicationRows";
import { NoResultsSVG } from "../../../assets";
import { ReactAdminTabbedShowNewAppWrapper } from "../../general/ReactAdminTabbedShowNewAppWrapper";
import { campaignCommunicationSortFields } from "../../../types/communications";

export function CampaignCommunicationList({ record }) {
  const { id: campaign_id } = record;
  const classes = styles();
  const [isReady, setIsReady] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const isMobile = useIsMobile();
  useScrollToTop();

  useEffect(() => {
    if (campaign_id) setIsReady(true);
  }, [campaign_id]);

  const listProps = useList({
    baseUrl: "/communications/campaign_communication_list",
    isReady,
    initialState: {
      sort: "communication_type",
      order: "asc",
      campaign_id,
    },
  });
  const { listIsFiltered, total, noResults, showRows } = listProps;

  return (
    <ReactAdminTabbedShowNewAppWrapper>
      <ListContainer listProps={listProps} topOffset={225} noPadding>
        {isMobile && (
          <div className={classes.topSection}>
            <div>
              <div className={classes.count}>
                {total} Communication
                {total !== 1 ? "s" : ""}
              </div>
            </div>
            <div>
              <IconButton onClick={() => setShowSort(true)}>
                <SwapVertIcon />
              </IconButton>
            </div>
          </div>
        )}

        {noResults && (
          <ListNoResults
            SVG={NoResultsSVG}
            listIsFiltered={listIsFiltered}
            label="communications"
          />
        )}

        {showRows && <CampaignCommunicationRows listProps={listProps} />}

        {showSort && (
          <MobileListSort
            onClose={() => setShowSort(false)}
            listProps={listProps}
            sortFields={campaignCommunicationSortFields}
          />
        )}
      </ListContainer>
    </ReactAdminTabbedShowNewAppWrapper>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      paddingBottom: 12,
      justifyContent: "space-between",
    },
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
}));
