import React, { useState, Fragment } from "react";
import { useNotify } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { APIUtilClient, generateAuthHeader } from "../../lib";

export function AssignPrizesModal({
  campaign_id,
  showAssignModal,
  setShowAssignModal,
  refetchListRef,
  prizePicksAllowed,
}) {
  const assignmentMethods = {
    ASSIGN: {
      label: "Assign needed prizes",
      value: "assign",
    },
    CLEAR_AND_REASSIGN: {
      label: "Clear old assignments and reassign",
      value: "clear_and_reassign",
    },
  };
  if (prizePicksAllowed) {
    assignmentMethods.ASSIGN_PICKED_ONLY = {
      label: "Assign student prize picks only",
      value: "assign_picked_only",
    };
    assignmentMethods.CLEAR_AND_REASSIGN_PICKED_ONLY = {
      label: "Clear old assignments and reassign student prize picks only",
      value: "clear_and_reassign_picked_only",
    };
  }
  const classes = styles();
  const notify = useNotify();
  const [submitting, setSubmitting] = useState(false);
  const [assignmentMethod, setAssignmentMethod] = useState(
    assignmentMethods.ASSIGN.value,
  );

  const handleClose = () => {
    setShowAssignModal(false);
    setSubmitting(false);
  };

  const assignPrizes = async () => {
    setSubmitting(true);
    const res = await APIUtilClient.post(
      "campaign_prize_programs/assign_prizes",
      { campaign_id, assignmentMethod },
      { headers: generateAuthHeader() },
    );
    refetchListRef.current();
    handleClose();
    const {
      error,
      errorMessage,
      data: { insufficientVolunteerIds, unassignedPrizePicksVolunteerIds } = {},
    } = res;
    if (error) {
      notify(errorMessage, { type: "warning" });
    } else if (
      Array.isArray(insufficientVolunteerIds) &&
      insufficientVolunteerIds.length
    ) {
      const failedIdStr = insufficientVolunteerIds.join(", ");
      notify(
        `Prizes have been successfully assigned,\nbut there where some Students we couldn't fully assign (out of stock, or Configurable prizes W/O selection, etc.)\nThese are the ID's: ${failedIdStr}`,
        { autoHideDuration: 20000, multiLine: true, type: "warning" },
      );
    } else if (
      Array.isArray(unassignedPrizePicksVolunteerIds) &&
      unassignedPrizePicksVolunteerIds.length
    ) {
      const failedIdStr = unassignedPrizePicksVolunteerIds.join(", ");
      notify(
        `Prize Picks have been successfully assigned,\nbut there where some Students we couldn't fully assign their prize picks (out of stock, already assigned other prizes, etc.)\nThese are the ID's: ${failedIdStr}`,
        { autoHideDuration: 20000, multiLine: true, type: "warning" },
      );
    } else {
      notify("Prizes have been successfully assigned!", { type: "info" });
    }
  };

  return (
    <Fragment>
      <Dialog open={showAssignModal} onClose={handleClose}>
        <DialogTitle>Assign Prizes</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.method}>
            Select Assignment Method
          </DialogContentText>
          <RadioGroup
            value={assignmentMethod}
            onChange={({ target }) => setAssignmentMethod(target.value)}
            className={classes.radioGroup}
          >
            {Object.keys(assignmentMethods).map(key => {
              const { label, value } = assignmentMethods[key];
              return (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={label}
                />
              );
            })}
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            disabled={submitting}
            onClick={assignPrizes}
          >
            Assign
            {submitting && (
              <CircularProgress size={30} className={classes.spinner} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  method: {
    marginBottom: 0,
  },
  radioGroup: {
    marginBottom: 24,
  },
  spinner: { position: "absolute", color: "#FFFFFF" },
}));
