import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  DateField,
  BooleanField,
  NumberInput,
} from "react-admin";

const Filters = props => {
  return (
    <Filter {...props}>
      <TextInput label="Email" source="email" alwaysOn />
      <NumberInput label="Campaign ID" source="campaign_id" alwaysOn />
    </Filter>
  );
};

export default function SentEmailsList(props) {
  return (
    <List
      {...props}
      perPage={25}
      title="Sent Emails"
      bulkActionButtons={false}
      exporter={false}
      filters={<Filters />}
      sort={{ field: "id", order: "DESC" }}
      empty={false}
    >
      <Datagrid rowClick={null}>
        <TextField source="id" />
        <TextField source="email_type" />
        <TextField source="email" sortable={false} />
        <DateField source="created_at" label="Sent On" showTime />
        <TextField source="delivery_status" label="Status" />
        <TextField
          source="undelivered_reason"
          label="Error Msg"
          sortable={false}
        />
        <TextField source="campaign_id" label="Campaign ID" />
        <TextField source="org_id" label="Org ID" />
        <TextField source="donation_id" label="Donation ID" />
        <TextField source="rep_id" label="Rep ID" />
        <TextField source="volunteer_id" label="Student ID" />
        <TextField source="contact_id" label="Contact ID" />
        <TextField
          source="communicationType"
          label="Communication Type"
          sortable={false}
        />
        <BooleanField source="from_drip_campaign" label="Drip Email" />
        <BooleanField source="is_cc_recipient" label="CC Recipient" />
        <TextField
          source="sendgrid_assigned_message_id"
          label="Msg ID"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
}
