import { Fragment, useState } from "react";
import { makeStyles, IconButton, Checkbox, Tooltip } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import classNames from "classnames";
import { GenericObject, ListProps, VolunteerQueryParams } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { useSelector } from "react-redux";
import {
  ImpersonateVolunteer,
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  MobileBulkSelectHeader,
  SortableColumnHeader,
} from "../../../components";
import { formatMoneyUnPadded, formatNumber } from "../../../lib";
import { getIsProductCampaign, getVolunteerLabel } from "../../../state";
import { ListPagination } from "../../../components/list/ListPagination";
import { RootState } from "../../../types/state";
import { VolunteerDetails } from "./VolunteerDetails";
const MIN_WIDTH = 1300;

type Props = {
  listProps: ListProps<VolunteerQueryParams>;
};
export function VolunteerRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    nextPage,
    paginationProps,
    allRowsSelected,
    selectAllRows,
    unselectAllRows,
    mobileSelectRowsMode,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isProduct = useSelector(getIsProductCampaign);
  const { volunteerLabel } = useSelector(getVolunteerLabel);
  const webpageLink = useSelector(
    ({ campaign }: RootState) => campaign.webpageLink,
  );

  return (
    <Fragment>
      {mobileSelectRowsMode && <MobileBulkSelectHeader listProps={listProps} />}
      <ListRowsWrapper>
        <ListRows minWidth={MIN_WIDTH}>
          {isDesktop && (
            <ListHeaderRowContainer>
              <div
                className={classNames(
                  classes.row,
                  isProduct && classes.productRow,
                )}
              >
                <div>
                  <Checkbox
                    checked={allRowsSelected}
                    onChange={() => {
                      allRowsSelected ? unselectAllRows() : selectAllRows();
                    }}
                  />
                </div>
                <div />
                <SortableColumnHeader
                  columnName="ID"
                  fieldName="id"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Name"
                  fieldName="last_name"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Activated"
                  fieldName="activated"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Page Enabled"
                  fieldName="webpage_enabled"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Class"
                  fieldName="class_name"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Raised"
                  fieldName="raised"
                  params={params}
                  setParams={setParams}
                />
                {isProduct && (
                  <SortableColumnHeader
                    columnName="Items sold"
                    fieldName="itemsSold"
                    params={params}
                    setParams={setParams}
                  />
                )}
                <SortableColumnHeader
                  columnName="Contacts confirmed"
                  fieldName="contacts_confirmed"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Valid contacts"
                  fieldName="validContacts"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Invalid contacts"
                  fieldName="invalidContacts"
                  params={params}
                  setParams={setParams}
                />
                <div />
              </div>
            </ListHeaderRowContainer>
          )}
          {rows.map((row, index) => {
            const lastRow = index === rows.length - 1;
            return (
              <Row
                key={row.id as number}
                row={row}
                lastRow={lastRow}
                listProps={listProps}
                mainWebpageLink={webpageLink}
              />
            );
          })}
        </ListRows>
        <ListPagination
          nextPage={nextPage}
          paginationProps={paginationProps}
          label={`${volunteerLabel} per page:`}
          minWidth={MIN_WIDTH}
        />
      </ListRowsWrapper>
    </Fragment>
  );
}

type RowProps = {
  listProps: ListProps<VolunteerQueryParams>;
  row: GenericObject;
  lastRow: boolean;
  mainWebpageLink: string;
};
function Row({ row, lastRow, mainWebpageLink, listProps }: RowProps) {
  const {
    refreshList,
    selectedRows,
    toggleSelectedRow,
    mobileSelectRowsMode,
  } = listProps;
  const classes = styles();
  const { volunteerLabelLCSingular } = useSelector(getVolunteerLabel);
  const isProduct = useSelector(getIsProductCampaign);
  const [showDetails, setShowDetails] = useState(false);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const {
    id,
    first_name,
    last_name,
    volunteerSlug,
    activated,
    class_name,
    contacts_confirmed,
    invalidContacts,
    validContacts,
    webpage_enabled,
    itemsSold,
    raised,
  } = row;
  const onClose = () => setShowDetails(false);

  return (
    <ListRowContainer
      clickable
      lastRow={lastRow}
      onClick={isDesktop ? () => setShowDetails(true) : undefined}
    >
      <div className={classNames(classes.row, isProduct && classes.productRow)}>
        {isDesktop && (
          <Fragment>
            <div className={classes.noClick} onClick={e => e.stopPropagation()}>
              <Checkbox
                checked={Boolean(selectedRows[id as number])}
                onChange={() => toggleSelectedRow(id as number, row)}
              />
            </div>
            <div className={classes.noClick}>
              <Tooltip title={`Impersonate ${volunteerLabelLCSingular}`}>
                <div>
                  <ImpersonateVolunteer volunteerId={id as number} />
                </div>
              </Tooltip>
            </div>

            <div>{id}</div>
            <div>
              {first_name} {last_name}
            </div>
            <div>
              {activated ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>
              {webpage_enabled ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>{class_name}</div>
            <div>{formatMoneyUnPadded(raised)}</div>
            {isProduct && <div>{formatNumber(itemsSold)}</div>}
            <div>
              {contacts_confirmed ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>{validContacts}</div>
            <div>{invalidContacts}</div>
            <div className={classes.noClick}>
              <Tooltip title={`Go to ${volunteerLabelLCSingular} page`}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  className={classes.viewPage}
                  href={`${mainWebpageLink}/${volunteerSlug}`}
                  target="_blank"
                  rel="noopener"
                  onClick={e => e.stopPropagation()}
                >
                  <OpenInNewIcon />
                </a>
              </Tooltip>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              {mobileSelectRowsMode && (
                <Checkbox
                  checked={Boolean(selectedRows[id as number])}
                  onChange={() => toggleSelectedRow(id as number, row)}
                  className={classes.checkbox}
                />
              )}
              <div className={classes.mobileInfo}>
                <div>{`#${id}`}</div>
                <div className={classes.name}>
                  {first_name} {last_name}
                </div>
                <div className={classes.contactsChip}>
                  {validContacts} valid contact{validContacts !== 1 ? "s" : ""}
                </div>
              </div>
            </div>
            <div className={classes.mobileRight}>
              <div className={classes.raised}>
                {formatMoneyUnPadded(raised)}
              </div>
              <div>
                <IconButton size="small" onClick={() => setShowDetails(true)}>
                  <ChevronRightIcon />
                </IconButton>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      {showDetails && (
        <VolunteerDetails
          id={id as number}
          onClose={onClose}
          refreshList={refreshList}
          rowData={row}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns:
      "56px 48px 90px 1fr 112px 102px .7fr 110px 116px 106px 106px 40px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 6px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  productRow: {
    gridTemplateColumns:
      "56px 48px 90px 1fr 112px 102px .7fr 110px 86px 116px 106px 106px 40px",
  },
  mobileLeft: {
    display: "flex",
    alignItems: "center",
  },
  mobileInfo: {
    overflow: "hidden",
    flex: 1,
  },
  checkbox: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -16,
    },
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingTop: 4,
      paddingBottom: 8,
      fontWeight: 500,
    },
  },
  raised: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 600,
      letterSpacing: 0.13,
      color: theme.palette.primary.main,
      minWidth: "fit-content",
      paddingRight: 16,
    },
  },
  contactsChip: {
    minHeight: 24,
    borderRadius: 12,
    backgroundColor: "#EFEFEF",
    padding: "3px 8px",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    textAlign: "center",
    fontSize: 13,
    letterSpacing: 0.15,
    color: theme.palette.text.secondary,
  },
  mobileRight: {
    display: "flex",
    alignItems: "center",
    minWidth: "fit-content",
  },
  noClick: {
    height: "100%",
    width: "100%",
    cursor: "auto",
    display: "flex",
    alignItems: "center",
    minHeight: 52,
    marginTop: -6,
    marginBottom: -6,
    paddingTop: 6,
    paddingBottom: 6,
    marginLeft: -18,
    paddingLeft: 18,
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  viewPage: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));
