import { Fragment, useState } from "react";
import EditIcon from "@material-ui/icons/EditOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import { makeStyles, IconButton } from "@material-ui/core";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../..";
import { ListPagination } from "../../list/ListPagination";
import { EditCommunication } from "../EditCommunication";
const MIN_WIDTH = 1100;

type Props = {
  listProps: ListProps;
};
export function CampaignCommunicationRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    nextPage,
    paginationProps,
    refreshList,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <Fragment>
      <ListRowsWrapper>
        <ListRows minWidth={MIN_WIDTH}>
          {isDesktop && (
            <ListHeaderRowContainer>
              <div className={classes.row}>
                <SortableColumnHeader
                  columnName="Type"
                  fieldName="communication_type"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Name"
                  fieldName="name"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Offset"
                  fieldName="offset"
                  params={params}
                  setParams={setParams}
                />
              </div>
            </ListHeaderRowContainer>
          )}
          {rows.map((row, index) => {
            const lastRow = index === rows.length - 1;
            return (
              <Row
                key={row.id as number}
                row={row}
                lastRow={lastRow}
                refreshList={refreshList}
              />
            );
          })}
        </ListRows>
        <ListPagination
          nextPage={nextPage}
          paginationProps={paginationProps}
          label="Communications per page:"
          minWidth={MIN_WIDTH}
        />
      </ListRowsWrapper>
    </Fragment>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => void;
};
function Row({ row, lastRow, refreshList }: RowProps) {
  const { name, communication_type, offset } = row;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{communication_type}</div>
            <div className={classes.name}>{name}</div>
            <div>{formatOffset(offset)}</div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div>{communication_type}</div>
              <div className={classes.name}>{name}</div>
              <div className={classes.offset}>{formatOffset(offset)}</div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showEdit && (
        <EditCommunication
          onClose={() => setShowEdit(false)}
          refreshList={refreshList}
          record={row}
        />
      )}
    </ListRowContainer>
  );
}

function formatOffset(offset: any) {
  let txt = "";
  switch (true) {
    case offset < 0:
      txt = `${Math.abs(offset)} Day${offset !== -1 ? "s" : ""} before`;
      break;
    case offset === 0:
      txt = "The Day of";
      break;
    case offset === 1:
      txt = `${offset} Day after`;
      break;
    default:
      txt = `${offset} Days after`;
  }
  return txt;
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 52px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 8px 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  mobileLeft: {
    overflow: "hidden",
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      paddingTop: 6,
      paddingBottom: 6,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  offset: {
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.text.secondary,
    },
  },
}));
