import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, requiredField, requiredInteger } from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { RootState } from "../../../types/state";
import {
  communicationTypeConfigs,
  CommunicationTypes,
} from "../../../types/communications";
import { Fragment, useState } from "react";
import { EmailWYSIWYGField } from "../../../components/communication/EmailWYSIWYGField";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};

export function AddCommunication({ onClose: _onClose, refreshList }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [type, setType] = useState<CommunicationTypes | "">("");
  const [typeSelected, setTypeSelected] = useState(false);
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const {
      offset,
      progress_percentage_min,
      progress_percentage_max,
      ...rest
    } = values;
    const post = {
      communication_type: type,
      active: true,
      offset: typeof offset !== "undefined" ? offset : 0,
      progress_percentage_min: progress_percentage_min
        ? Number(progress_percentage_min)
        : null,
      progress_percentage_max: progress_percentage_max
        ? Number(progress_percentage_max)
        : null,
      ...rest,
    };
    const url = `/communications`;
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Communication added", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Add communication</h1>
                {isMobile && (
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              <div className={classes.mainContent}>
                <TextField
                  select
                  className={classes.input}
                  disabled={typeSelected}
                  label="Communication type"
                  value={type}
                  onChange={({ target }) =>
                    setType(target.value as CommunicationTypes)
                  }
                >
                  {Object.values(CommunicationTypes).map(type => {
                    return (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })}
                </TextField>

                {type && typeSelected && <Content type={type} />}
              </div>

              <div className={classes.bottom}>
                {!isMobile && (
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting || !type}
                  onClick={() => {
                    !typeSelected ? setTypeSelected(true) : form.submit();
                  }}
                >
                  {!typeSelected ? "Continue" : "Save"}
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

type ContentProps = {
  type: CommunicationTypes;
};
function Content({ type }: ContentProps) {
  const classes = styles();
  const communicationCategoryAutocomplete = useSelector(
    ({ communication }: RootState) =>
      communication.communicationCategoryAutocomplete,
  );

  const {
    offsetLabel,
    allowOrgLogoInsert,
    allowSocialMediaInsert,
    allowWebpageButton,
    allowVolunteerPictureInsert,
    noOffset,
    showPercents,
    allowCcRep,
    mergeFields,
    offsetOptions,
  } = communicationTypeConfigs[type];

  return (
    <Fragment>
      <Field
        select
        component={TextFieldWrapper}
        name="communication_category_id"
        label="Category"
        validate={requiredField}
        className={classes.input}
      >
        {communicationCategoryAutocomplete.map(c => {
          const { id, name } = c;
          return (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        component={TextFieldWrapper}
        name="name"
        label="Name"
        validate={requiredField}
        className={classes.input}
      />

      {!noOffset && (
        <Field
          select
          component={TextFieldWrapper}
          name="offset"
          label={offsetLabel}
          validate={requiredInteger}
          className={classes.input}
        >
          {offsetOptions.map(o => {
            const { name, value } = o;
            return (
              <MenuItem key={name} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </Field>
      )}

      {showPercents && (
        <div className={classes.inputGroup}>
          <Field
            component={TextFieldWrapper}
            name="progress_percentage_min"
            label="Min percent"
            validate={requiredInteger}
            className={classes.halfInput}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <Field
            component={TextFieldWrapper}
            name="progress_percentage_max"
            label="Max percent"
            validate={requiredInteger}
            className={classes.halfInput}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </div>
      )}

      {allowOrgLogoInsert && (
        <div className={classes.input}>
          <SwitchField fieldName="insert_org_logo" label="Insert org logo" />
        </div>
      )}

      {allowVolunteerPictureInsert && (
        <div className={classes.input}>
          <SwitchField
            fieldName="insert_volunteer_picture"
            label="Insert student picture"
          />
        </div>
      )}

      {allowSocialMediaInsert && (
        <div className={classes.input}>
          <SwitchField
            fieldName="insert_social_media_links"
            label="Insert social media links"
          />
        </div>
      )}

      {allowWebpageButton && (
        <div className={classes.input}>
          <SwitchField
            fieldName="insert_webpage_button"
            label="Insert webpage button"
          />
        </div>
      )}

      {allowCcRep && (
        <div className={classes.input}>
          <SwitchField fieldName="cc_rep" label="cc rep" />
        </div>
      )}

      <EmailWYSIWYGField
        header="Email subject"
        fieldName="email_subject"
        mergeFields={mergeFields}
      />
      <div className={classes.space} />
      <EmailWYSIWYGField
        header="Email body"
        fieldName="email_body"
        mergeFields={mergeFields}
        addMaterialMerge
      />
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      position: "sticky",
      top: 0,
      minHeight: "unset",
      borderBottom: "none",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  space: {
    height: 32,
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
