import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  Pagination,
  BooleanField,
  NumberInput,
} from "react-admin";
import OpenInNew from "@material-ui/icons/OpenInNew";
import { getCampaignVolunteerListProps } from "../../customState";
import { isHibernateCompany, isProductCampaign, mainSiteUrl } from "../../lib";
import { CompanyEditVolunteer } from "./CompanyEditVolunteer";
import { VolunteerListActions } from "./VolunteerListActions";
import { styles } from "./Volunteer.styles";

export default function VolunteerList({ record, forCampaignShow }) {
  const classes = styles();
  // TODO: now that we moved the org portal to the new app this can be redone straight up for companies
  const props = useSelector(state =>
    getCampaignVolunteerListProps(state, record, forCampaignShow),
  );
  const { campaign_id, campaign_type_id, volunteer_label } = props;

  const listProps = {
    location: {},
    resource: "volunteers",
    basePath: forCampaignShow
      ? `/campaigns/${campaign_id}/show/volunteers`
      : "/volunteers",
    perPage: 25,
    sort: { field: "last_name", order: "ASC" },
    filter: { campaign_id, campaign_type_id },
  };

  if (!campaign_id || !campaign_type_id) return <></>;
  return (
    <Fragment>
      <List
        {...listProps}
        title={forCampaignShow ? " " : `${volunteer_label}s`}
        bulkActionButtons={false}
        filters={
          <Filter>
            <TextInput
              InputLabelProps={{ classes: { root: classes.searchLabel } }}
              label={`Search ${volunteer_label}s`}
              source="q"
              alwaysOn
            />
            <NumberInput
              InputLabelProps={{ classes: { root: classes.searchLabel } }}
              label={`${volunteer_label} ID`}
              source="volunteer_id"
              alwaysOn
            />
            <TextInput label="Email" source="email" alwaysOn />
          </Filter>
        }
        actions={<VolunteerListActions {...props} />}
        empty={false}
        component={forCampaignShow ? "div" : undefined}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
      >
        <GridWrapper parentProps={props} forCampaignShow={forCampaignShow} />
      </List>
    </Fragment>
  );
}

function GridWrapper({ forCampaignShow, parentProps, ...DGProps }) {
  const {
    classOptions,
    companyId,
    campaign_type_id,
    allow_associated_volunteer_accounts,
  } = parentProps;
  const classes = styles();
  const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);
  const isHibernate = isHibernateCompany(companyId);
  const isProduct = isProductCampaign(campaign_type_id);

  return (
    <div
      className={
        sidebarOpen ? classes.datagridBarOpen : classes.datagridBarClosed
      }
    >
      <Datagrid
        {...DGProps}
        rowClick={forCampaignShow ? "expand" : "show"}
        expand={
          forCampaignShow ? (
            <CompanyEditVolunteer
              classOptions={classOptions}
              allow_associated_volunteer_accounts={
                allow_associated_volunteer_accounts
              }
            />
          ) : null
        }
        refresh={true}
      >
        <TextField source="id" label="ID" />
        <FunctionField
          sortBy="last_name"
          label="Name"
          render={record => `${record.first_name} ${record.last_name}`}
        />
        <BooleanField source="activated" />
        <FunctionField
          label=""
          style={{ width: "20px" }}
          render={record => {
            const {
              orgSlug,
              slug,
              campaign_slug,
              company_frontend_url,
            } = record;
            return (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                className={classes.siteLink}
                href={mainSiteUrl(
                  orgSlug,
                  campaign_slug,
                  slug,
                  company_frontend_url,
                )}
                target="_blank"
                rel="noopener"
                onClick={e => e.stopPropagation()}
              >
                <OpenInNew />
              </a>
            );
          }}
        />
        <TextField source="email" sortable={false} />
        <TextField source="phone" sortable />
        {classOptions && (
          <TextField source="class_name" label="Class" sortable />
        )}
        <TextField source="slug" label="Unique URL" sortable />
        {(!isHibernate || !isProduct) && (
          <FunctionField
            source="raised"
            label={`${isProduct ? "Total Sales" : "Raised"}`}
            render={record => record.raised}
          />
        )}
        {isProduct && <TextField source="itemsSold" label="Total Items Sold" />}
        {isProduct && isHibernate && (
          <TextField source="sheets" label="Sheets Sold" />
        )}
        {isProduct && isHibernate && (
          <TextField source="pillows" label="Pillows Sold" />
        )}
        <BooleanField source="contacts_confirmed" label="Contacts Confirmed" />
        <TextField source="validContactCount" label="Valid Contacts" />
        <FunctionField
          source="invalidContactCount"
          label="Invalid Contacts"
          render={({ invalidContactCount }) => (
            <div className={classes.invalidContactCell}>
              <div className={classes.invalidCount}>{invalidContactCount}</div>
            </div>
          )}
        />
        <FunctionField
          source="contactsEmailed"
          label="Emails Manually sent to:"
          render={record => (
            <div className={classes.emailsSent}>
              {`${record.contactsEmailed} Contact(s)`}
            </div>
          )}
        />
      </Datagrid>
    </div>
  );
}
