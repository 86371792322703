import { ListProps } from "../../../types";
import { FiltersDrawer, SwitchFilter } from "../../../components";
import { Fragment } from "react";
import { CommunicationFields } from "../../../types/communications";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function CommunicationBundleFilters({ onClose, listProps }: Props) {
  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header="Filter bundles"
    >
      <Fragment>
        <SwitchFilter
          field={CommunicationFields.include_inactive}
          chipText="Include inactive"
          header="Include inactive bundles"
          switchLabel="Include inactive"
          listProps={listProps}
        />
      </Fragment>
    </FiltersDrawer>
  );
}
