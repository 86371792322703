import { makeStyles, Tabs, Tab } from "@material-ui/core";
import { ReactAdminNewAppWrapper } from "../../components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/state";
import { useHistory } from "react-router-dom";
import { Communications } from "./library/Communications";
import { CommunicationBundles } from "./bundles/CommunicationBundles";
import { isCompanyOrCompanyAdmin } from "../../../lib";
import { Categories } from "./categories/Categories";
import { materialActions } from "../../state";
enum Screens {
  Lib = "Lib",
  Bundles = "Bundles",
  Categories = "Categories",
}

export function CommunicationLibrary() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { role } = useSelector(({ user }: RootState) => user);
  const [activeTab, setActiveTab] = useState<Screens>(Screens.Lib);

  useEffect(() => {
    if (!isCompanyOrCompanyAdmin(role)) {
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    dispatch(materialActions.fetchMaterialAutocomplete());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactAdminNewAppWrapper>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        classes={{ root: classes.tabs }}
      >
        <Tab
          label="Communication Library"
          value={Screens.Lib}
          className={classes.tab}
        />
        <Tab
          label="Communication Bundles"
          value={Screens.Bundles}
          className={classes.tab}
        />
        <Tab
          label="Categories"
          value={Screens.Categories}
          className={classes.tab}
        />
      </Tabs>

      {activeTab === Screens.Lib && <Communications />}
      {activeTab === Screens.Bundles && <CommunicationBundles />}
      {activeTab === Screens.Categories && <Categories />}
    </ReactAdminNewAppWrapper>
  );
}

const styles = makeStyles(theme => ({
  tabs: {
    borderBottom: "1px solid #DBDEEE",
    margin: "0 32px 18px 32px",
    position: "sticky",
    top: 56,
    backgroundColor: "#F7F7F7",
    zIndex: 9,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      top: 56,
    },
  },
  tab: {
    maxWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));
